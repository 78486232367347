/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This function returns the details used to populate
 * the site metadata.
 *
 * @param {string} pathname - the current pathname
 * @returns {object} the page metadata
 */
const siteMetadata = (pathname) => {
  const { title, description } = switchPageDescription(pathname.split("/"));
  return {
    title,
    description,
    author: "Revere",
    lang: "en",
  };
};

export default siteMetadata;

/**
 *
 * @param {array} subroutes
 * @returns
 */
const switchPageDescription = (subroutes) => {
  switch (`/${subroutes[1]}`) {
    case "/":
      return {
        title: "Home",
        description: "",
      };
    default:
      return {
        title: "",
        description: "",
      };
  }
};
