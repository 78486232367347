/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const StyledDialogContent = ({
  content,
  children,
  center = false,
  noPadding = false,
}) => {
  return (
    <DialogContent sx={noPadding ? { p: 0 } : { py: 1, px: 2 }}>
      <Typography color="textSecondary" align={center ? "center" : "left"}>
        {content}
      </Typography>
      {children}
    </DialogContent>
  );
};

StyledDialogContent.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StyledDialogContent;
