/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import { Helmet } from "react-helmet";

// Typography
import "@fontsource/dm-sans/500.css";
import "@fontsource/poppins/500.css";
import poppins from "@fontsource/poppins/files/poppins-latin-ext-400-normal.woff2";
import poppins500 from "@fontsource/poppins/files/poppins-latin-ext-500-normal.woff2";
import sans from "@fontsource/dm-sans/files/dm-sans-latin-ext-400-normal.woff2";
import sans500 from "@fontsource/dm-sans/files/dm-sans-latin-ext-500-normal.woff2";

// Functions
import { useTheme } from "@mui/system";

const SEO = ({ description, lang, title }) => {
  const theme = useTheme();

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={"%s | 3Mail"}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "theme-color",
          content: theme.palette.background.paper,
          media: "(prefers-color-scheme: light)",
        },
        {
          name: "theme-color",
          content: theme.palette.background.paper,
          media: "(prefers-color-scheme: dark)",
        },
      ]}
    >
      <link rel="preload" as="font" href={poppins} type="font/woff2" />
      <link rel="preload" as="font" href={poppins500} type="font/woff2" />
      <link rel="preload" as="font" href={sans} type="font/woff2" />
      <link rel="preload" as="font" href={sans500} type="font/woff2" />
    </Helmet>
  );
};

export default SEO;
