const getRandomLogoHelper = () => {
  let rand = Math.random();
  if (rand < 0.33) return revere1;
  else if (rand >= 0.33 && rand < 0.67) return revere2;
  return revere3;
};

const revere1 = String.raw`
    Revere Labs ⓒ ${new Date().getFullYear()}
   ___  _____   _________  ____
  / _ \/ __/ | / / __/ _ \/ __/
 / , _/ _/ | |/ / _// , _/ _/  
/_/|_/___/ |___/___/_/|_/___/
                 
============================                
/       REVERE LABS        /
============================`;

const revere2 = String.raw`
Revere Labs ⓒ ${new Date().getFullYear()}
____  _______     _______ ____  _____ 
|  _ \| ____\ \   / / ____|  _ \| ____|
| |_) |  _|  \ \ / /|  _| | |_) |  _|  
|  _ <| |___  \ V / | |___|  _ <| |___ 
|_| \_\_____|  \_/  |_____|_| \_\_____|
                                                          
============================              
/       REVERE LABS        /
============================  
`;

const revere3 = String.raw`
Revere Labs ⓒ ${new Date().getFullYear()}
____ ____ ____ ____ ____ ____ 
||R |||E |||V |||E |||R |||E ||
||__|||__|||__|||__|||__|||__||
|/__\|/__\|/__\|/__\|/__\|/__\|

============================                
  /       REVERE LABS        /
============================
`;

export const message = `${getRandomLogoHelper()}

3Mail is made by small, but mighty team who call themselves
Revere Labs. Soon we will be looking for more curious minds
like yours to join our team. Up for a new adventure?`;
