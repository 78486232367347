/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledLinearProgress from "../../../UI/Progress/styledLinearProgress";
import StyledDivider from "../../../UI/Divider/styledDivider";

// Material UI
import { Box, alpha } from "@mui/system";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

// Component
const StyledDialogHeader = ({
  title,
  isMobile,
  backButton,
  progress,
  color = "secondary",
  handleClose,
  hasDivider = true,
  hasButton = true,
  secondaryIcon = null,
  noBottomPadding = false,
  hasSecondaryButton = false,
  handleSecondaryClick = null,
}) => {
  return (
    <Grid
      sx={{
        pt: 1,
        pb: noBottomPadding ? 0 : 0.5,
        px: 1.5,
      }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={hasSecondaryButton ? 10 : 12}>
        <DialogTitle sx={{ p: hasButton ? 0.5 : 0.75 }}>
          {hasButton && (
            <IconButton
              sx={{
                mr: 1,
                color: "text.secondary",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.background.default
                    : theme.palette.grey[100],
                "&:hover": {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? alpha(theme.palette.background.default, 0.5)
                      : theme.palette.grey[50],
                },
              }}
              aria-label={backButton ? "Go back" : "Close this Dialog"}
              onClick={handleClose}
              size="medium"
            >
              {backButton ? (
                <ArrowBackRoundedIcon
                  fontSize={isMobile ? "small" : "medium"}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.background.paper
                        : theme.palette.text.primary,
                  }}
                />
              ) : (
                <CloseRoundedIcon
                  fontSize={isMobile ? "small" : "medium"}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[50]
                        : theme.palette.text.primary,
                  }}
                />
              )}
            </IconButton>
          )}
          {title}
        </DialogTitle>
      </Grid>
      {hasSecondaryButton && (
        <Grid item xs={2}>
          <Box textAlign="right">
            <IconButton
              size={isMobile ? "small" : "medium"}
              onClick={handleSecondaryClick}
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.secondary
                    : theme.palette.text.secondary,
                "&:hover": {
                  backgroundColor: "grey.50",
                },
              }}
            >
              {secondaryIcon}
            </IconButton>
          </Box>
        </Grid>
      )}
      {hasDivider &&
        (!progress ? (
          <StyledDivider sxProps={{ mt: 1 }} light={false} />
        ) : (
          <StyledLinearProgress
            variant="determinate"
            value={progress}
            color={color}
          />
        ))}
    </Grid>
  );
};

StyledDialogHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  handleClose: PropTypes.func,
  isMobile: PropTypes.bool,
  backButton: PropTypes.bool,
  noBottomPadding: PropTypes.bool,
  progress: PropTypes.number,
  hasButton: PropTypes.bool,
  secondaryIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  hasSecondaryButton: PropTypes.bool,
  handleSecondaryClick: PropTypes.func,
};

export default StyledDialogHeader;
