/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { NavLink } from "react-router-dom";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { styled, Box } from "@mui/system";

const StyledFooter = styled("footer")(({ theme }) => ({
  padding: theme.spacing(0.75),
  backgroundColor: theme.palette.secondary.main,
  marginTop: theme.spacing(1),
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          {navDrawerLinks.map((link, index) => (
            <React.Fragment key={index}>
              <Box textAlign="center">
                <Button
                  sx={{
                    color: "primary.contrastText",
                    py: 1,
                    px: 2,
                  }}
                  component={link.type === "nav" ? NavLink : link.type}
                  to={link.type === "nav" ? link.route : null}
                  href={link.type === "a" ? link.route : null}
                  rel={link.type === "a" ? "noreferrer" : null}
                  referrerPolicy={link.type === "a" ? "no-referrer" : null}
                  target={link.type === "a" ? "_blank" : null}
                >
                  {link.text}
                </Button>
              </Box>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12} sx={{ color: "primary.contrastText", mb: 0.5 }}>
          <Typography align="center" variant="body2">
            Proudly made in Austin, Texas
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ color: "primary.contrastText", mb: 0.5 }}>
          <Typography
            align="center"
            variant="body2"
            sx={{ fontSize: 11, fontWeight: 500 }}
          >
            © {new Date().getFullYear()}
            {` `}
            <Link
              sx={{ color: "primary.contrastText" }}
              href="https://www.revere.chat"
              rel="noreferrer"
              referrerPolicy="no-referrer"
              target="_blank"
              underline="none"
            >
              Revere Labs
            </Link>{" "}
            - Dare to speak freely
          </Typography>
        </Grid>
      </Grid>
    </StyledFooter>
  );
};

export default Footer;

const navDrawerLinks = [
  {
    text: "Home",
    route: "/",
    type: "nav",
    id: "home",
    icon: <></>,
  },
  // {
  //   text: "Whitepaper",
  //   route: "/governance",
  //   type: "nav",
  //   icon: <></>,
  // },
  {
    text: "Discord",
    route:
      "https://discord.com/channels/1049918494008938517/1049927399715913818",
    type: "a",
    id: "discord",
    icon: <></>,
  },
  {
    text: "Revere Labs",
    route: "https://www.revere.chat",
    type: "a",
    id: "revere",
  },
];
